import styled from 'styled-components';
import {
  space,
  color,
  typography,
  compose,
  ColorProps,
  SpaceProps,
  TypographyProps
} from 'styled-system';

const Pre = styled.pre<ColorProps & SpaceProps & TypographyProps>`
  ${compose(color, space, typography)};
  white-space: normal;
`;

Pre.defaultProps = {
  bg: 'neutral.1',
  p: 4,
  fontSize: 2
};

export default Pre;
