import { FC } from 'react';
import * as React from 'react';
import styled from 'styled-components';
import dynamic from 'next/dynamic';
import { FormattedMessage, FormattedDate, FormattedTime } from 'react-intl';
import {
  Heading as H1,
  H2,
  H3,
  Anchor,
  Text,
  List,
  Image,
  ButtonAnchor,
  Button,
  Box,
  BoxProps
} from '@urbaninfrastructure/react-ui-kit';
import SanityBlockContent from '@sanity/block-content-to-react';
import Link from '../Link';
import { BreakoutWrapper } from '../BreakoutWrapper';
import SanityLink, { SanityLinkType } from '../SanityLink';
import Pre from './Pre';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { dockGroupCount, settings } from '../../core-types';
import { I18NPluralStations } from '../common-i18n';
import { useSettingsQuery } from '../../lib/core/SettingsQuery';
import { useStaticDataQuery } from '../../lib/core/staticDataQuery';

const MarkdownRenderer = dynamic(() => import('./MarkdownRenderer'));

type LinkSerializerProps = {
  mark: { href: string; _type: string; _refType: string; slug?: string };
};

const imageOptions = { w: 1200, h: 600, fit: 'max' };

const Noop: FC<any> = props => {
  return props.children;
};

const PageSerializer: FC<LinkSerializerProps> = props => {
  if (!props.mark || !props.mark?._refType) {
    return Noop(props);
  }

  return (
    <Link
      route={props.mark._refType}
      params={{ slug: props.mark.slug }}
      passHref
    >
      <Anchor>{props.children}</Anchor>
    </Link>
  );
};

const Base = styled(Box)<{ appContent?: boolean }>`
  p,
  ul,
  ol {
    font-size: ${props => props.appContent && `${props.theme.textSize}px`};
    font-weight: normal;
  }
  ul,
  ol {
    margin-top: ${props => props.theme.space[6]}px;
    margin-bottom: ${props => props.theme.space[6]}px;
    text-align: left;
  }
  li {
    margin-bottom: ${props => props.theme.space[4]}px;
  }
  figure {
    margin: 0;
  }
`;

const BlockRenderer: FC<{
  node: { style: string };
}> = props => {
  const style = props.node.style || 'normal';

  if (/^h\d/.test(style)) {
    const level = style.replace(/[^\d]/g, '');
    if (level === '1') {
      return <H1 data-testid="BlockRenderer__Heading1">{props.children}</H1>;
    }
    if (level === '2') {
      return (
        <H2 data-testid="BlockRenderer__Heading2" mt="md">
          {props.children}
        </H2>
      );
    }
    if (level === '3') {
      return <H3 mt="sm">{props.children}</H3>;
    }
  }

  switch (style) {
    case 'blockquote':
      return <blockquote>{props.children}</blockquote>;
    case 'pre':
      return <Pre>{props.children}</Pre>;
    case 'lead':
      return (
        <Text as="p" fontWeight="normal" lead>
          {props.children}
        </Text>
      );
    case 'small':
      return (
        <Text as="p" fontWeight="normal" small>
          {props.children}
        </Text>
      );
    default:
      return (
        <Text as="p" fontWeight="normal">
          {props.children}
        </Text>
      );
  }
};

const CTALinkRenderer: FC<{
  node: {
    link: SanityLinkType | null;
    _key: string;
    _type: 'ctaLink';
  };
}> = props => {
  if (!props.node || !props.node.link || !props.node.link.title) {
    return null;
  }
  const { link } = props.node;
  return (
    <Box my="xs" textAlign="center">
      <SanityLink link={link}>
        {anchorProps => {
          const variant = 'primary';
          if (anchorProps.href) {
            return (
              <ButtonAnchor
                variant={variant}
                shape="cool"
                href={anchorProps.href}
              >
                {link.title}
              </ButtonAnchor>
            );
          }
          if (anchorProps.onClick) {
            return (
              <Button
                variant={variant}
                shape="cool"
                onClick={anchorProps.onClick}
              >
                {link.title}
              </Button>
            );
          }
          return null;
        }}
      </SanityLink>
    </Box>
  );
};

const DOCK_GROUP_COUNT_QUERY = gql`
  query dockGroupCount {
    dockGroupCount
  }
`;

function DockGroupCountRenderer({ node }: { node: { formatted: boolean } }) {
  const { data, loading } = useQuery<dockGroupCount>(DOCK_GROUP_COUNT_QUERY);
  const count = loading ? '...' : (data && data.dockGroupCount) || 0;
  if (node.formatted) {
    return (
      <FormattedMessage
        id="BlockContent.DockGroupCountRenderer.stations"
        defaultMessage="{count} {pluralStations}"
        values={{
          count,
          pluralStations: <I18NPluralStations values={{ count }} />
        }}
      />
    );
  }
  return count;
}

const createSystemSeasonStartDateRenderer = ({ data }: { data?: settings }) => {
  return function SystemSeasonStartDateRenderer() {
    if (!data?.system?.season) {
      return null;
    }
    const { startDate } = data.system.season;

    if (!startDate) {
      return null;
    }

    return <FormattedDate value={startDate} month="long" day="numeric" />;
  };
};

const createSystemWorkingHoursRenderer = ({ data }: { data?: settings }) => {
  return function SystemWorkingHoursRenderer(props) {
    if (!data?.system?.workingHours) {
      return null;
    }
    const field = props.node && props.node.field;
    if (!field) {
      return null;
    }
    const date = data.system.workingHours[field];

    if (!date) {
      return null;
    }

    return <FormattedTime value={date} />;
  };
};

export const BlockContent = ({
  withoutAnchors,
  blocks,
  color,
  mb = 6,
  breakoutImages = true,
  imageProps = {
    boxShadow: 'heavy',
    borderRadius: 'lg'
  },
  ...props
}: BoxProps & {
  breakoutImages?: boolean;
  imageProps?: { boxShadow?: 'heavy'; borderRadius?: 'lg' };
  withoutAnchors?: boolean;
  blocks?: any;
  serializers?: {
    marks: any;
    types: any;
    list: any;
  };
}) => {
  let marks: { link?: React.ReactNode; page?: React.ReactNode } = {
    page: PageSerializer
  };

  if (withoutAnchors) {
    marks = { ...marks, link: Noop, page: Noop };
    marks.link = Noop;
  }

  const staticDataResult = useStaticDataQuery();
  const { data } = useSettingsQuery();

  const staticDataSystem =
    staticDataResult.data && staticDataResult.data.system;

  const types = {
    markdown: MarkdownRenderer,
    image: function ImageRenderer(props: {
      node: { alt?: string };
      options: any;
    }) {
      const image = (
        <Image
          {...imageProps}
          alt={props.node.alt}
          src={SanityBlockContent.getImageUrl({
            node: Object.assign({}, props.node), // workaround for TypeError: Cannot assign to read only property 'crop' of object '#<Object>' error
            options: props.options
          })}
        />
      );
      if (!breakoutImages) {
        return <Box mb="xs">{image}</Box>;
      }
      return (
        <BreakoutWrapper display="flex" justifyContent="center" my="md">
          {image}
        </BreakoutWrapper>
      );
    },
    dockGroupCount: DockGroupCountRenderer,
    systemSeasonStartDate: createSystemSeasonStartDateRenderer({
      data
    }),
    systemWorkingHours: createSystemWorkingHoursRenderer({
      data
    }),
    block: BlockRenderer,
    ctaLink: CTALinkRenderer
  };

  const serializers = {
    marks,
    types,
    list: function ListSerializer({
      type,
      children
    }: {
      type: 'bullet' | 'number';
      children: React.ReactNode;
    }) {
      const props: { listStyle: string; as: 'ul' | 'ol' } = {
        as: 'ul',
        listStyle: 'disc'
      };
      if (type === 'number') {
        props.listStyle = 'decimal';
        props.as = 'ol';
      }
      return (
        <List
          as={props.as}
          listStyle={props.listStyle}
          pl={{ xs: 4, lg: 5, xl: 0 }}
          my="md"
        >
          {children}
        </List>
      );
    }
  };

  return (
    <Base
      data-testid="BlockContentBase"
      appContent={withoutAnchors}
      color={color as any}
      mb={mb}
      {...props}
    >
      <SanityBlockContent
        projectId={staticDataSystem && staticDataSystem.sanityProjectId}
        dataset={staticDataSystem && staticDataSystem.sanityDataset}
        serializers={serializers}
        imageOptions={imageOptions}
        blocks={blocks}
      />
    </Base>
  );
};
